









































































































































































































































































import {
  SfLoader,
  SfNotification,
  SfSidebar,
  SfHeading,
  SfButton,
  SfProperty,
  SfPrice,
  SfCollectedProduct,
  SfQuantitySelector,
  SfBadge,
  SfImage,
} from '@storefront-ui/vue';
import {
  defineComponent,
  useRouter,
  useContext,
} from '@nuxtjs/composition-api';
import {
  useUiState,
} from '@wemade-vsf/composables';
import CouponCode from 'components/checkout/Cart/CouponCode.vue';
import { useCartView } from '@wemade-vsf/checkout';
import { useCustomerPricing } from '@wemade-vsf/catalog/lib/runtime/product/composables/useCustomerPricing';
import CartItemAdditionalInformation from 'components/checkout/Cart/CartItemAdditionalInformation.vue';

export default defineComponent({
  name: 'CartSidebar',
  components: {
    SfLoader,
    SfNotification,
    SfSidebar,
    SfButton,
    SfHeading,
    SfProperty,
    SfPrice,
    SfCollectedProduct,
    SfQuantitySelector,
    SfBadge,
    CouponCode,
    SfImage,
    CartItemAdditionalInformation
  },
  setup() {
    const cartView = useCartView();
    const { isCartSidebarOpen, toggleCartSidebar } = useUiState();
    const { displayPricesIncludingTax: arePricesIncludingTax } = useCustomerPricing();
    const router = useRouter();
    const { app } = useContext();

    const goToCart = async () => {
      await router.push(app.localeRoute({ name: 'cart' }));
    };


    return {
      ...cartView,
      arePricesIncludingTax,
      isCartSidebarOpen,
      toggleCartSidebar,
      goToCart,
    };
  },
});
